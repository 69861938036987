import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Homepage';
import PayFrameDemo from './components/PayFrameDemo';
import Demoiframe from './components/Demoiframe';
import NewIframe from './components/NewIframe';
import CreatePayment from './components/CreatePayment';
import About from './components/About';
import PaymentSuccessful from './components/PaymentSuccessful';
import RegII from './components/blogs/RegII';
import BoostRevenue from './components/blogs/BoostRevenue';
import IntroPayments from './components/blogs/IntroPayments';
import PaymentMap from './components/blogs/PaymentMap';
import TermsAndConditions from './components/TC/TermsAndConditions';
import './App.css';  
import BlogsPage from './components/TC/BlogsPage';
import BlogsArticle from './components/BlogsArticle';
import DashboardTest from './components/DashboardTest';
import PayWidget from './components/PayWidget';
import RequestWidget from './components/RequestWidget';
import TransactionsWidget from './components/transactionsWidget';
// import TransactionsWidget from './components/TransactionsWidget';





function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/demo" element={<PayFrameDemo />} />
        <Route path="/iFramelowcode" element={<Demoiframe />} />
        <Route path="/Createorder" element={<NewIframe />} />
        <Route path="/Createpayment" element={<CreatePayment/>} />
        <Route path="/About" element={<About/>} />
        <Route path="/Paymentsuccessful" element={<PaymentSuccessful/>} />
        <Route path="/how-reg-II-is-changing-payments" element={<RegII/>} />
        <Route path="/introduction-to-payments" element={<IntroPayments/>} />
        <Route path="/accelerating-your-payment-roadmap" element={<PaymentMap/>} />
        <Route path="/boosting-revenue-with-embedded-payments" element={<BoostRevenue/>} />
        <Route path="/Fractal-Terms-And-Conditions" element={<TermsAndConditions/>} />
        <Route path="/blogs" element={<BlogsPage/>} />
        <Route path="/blogs-article" element={<BlogsArticle/>} />
        <Route path="/DashboardTest" element={<DashboardTest/>} />
        <Route path="/PayWidget" element={<PayWidget/>} />
        <Route path="/RequestWidget" element={<RequestWidget/>} />
        <Route path="/TransactionsWidget" element={<TransactionsWidget/>} />
        
        {/* Other routes */}
      </Routes>
    </Router>
  );
}

export default App;
