import React, { useState } from 'react';
import './InvoiceTable.css';
import { RequestPaymentonClick } from 'fractalpay';

const InvoiceTableRequest = () => {
  const fractalpayClientKey = "2c420c812f87c294d9890b6df134fcd6";
  
  const [phoneNumbers, setPhoneNumbers] = useState({});

  const handlePhoneNumberChange = (id, value) => {
    // Remove non-digit characters and limit the length to 10 digits
    const formattedValue = value.replace(/\D/g, '').slice(0, 10);
    setPhoneNumbers(prevState => ({
      ...prevState,
      [id]: formattedValue
    }));
  };

  const invoices = [
    { id: "000004", name: "Molly Sanders", amount: 4.55, issued: "Sept 28, 2019", due: "Due in 3 days", status: "Pending", profileImg: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" },
    { id: "000003", name: "Michael Roberts", amount: 8.55, issued: "Sept 25, 2019", due: "Due in 6 days", status: "Pending", profileImg: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" },
    { id: "000002", name: "Devin Childs", amount: 7.19, issued: "Sept 14, 2019", due: "Due in 2 weeks", status: "Pending", profileImg: "https://images.unsplash.com/photo-1540845511934-7721dd7adec3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" },
    { id: "000001", name: "Frederick Nicholas", amount: 12.00, issued: "Sept 6, 2019", due: "Due 3 weeks ago", status: "Overdue", profileImg: "https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&h=160&w=160&q=80" }
  ];

  return (
    <div>
      <div className="container">
        <div className="py-8">
          <div>
            <h2 className="text-2xl font-semibold leading-tight">Invoices</h2>
          </div>
          <div className="overflow-x-auto">
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th className="table-header">Client / Invoice</th>
                    <th className="table-header">Amount</th>
                    <th className="table-header">Issued / Due</th>
                    <th className="table-header">Status</th>
                    <th className="table-header">Phone Number</th>
                    <th className="table-header"></th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map(invoice => (
                    <tr key={invoice.id}>
                      <td className="table-cell">
                        <div className="flex">
                          <div className="profile-img-wrapper">
                            <img className="profile-img" src={invoice.profileImg} alt="Profile" />
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">{invoice.name}</p>
                            <p className="text-gray-600 whitespace-no-wrap">{invoice.id}</p>
                          </div>
                        </div>
                      </td>
                      <td className="table-cell">
                        <p className="text-gray-900 whitespace-no-wrap">${invoice.amount}</p>
                        <p className="text-gray-600 whitespace-no-wrap">USD</p>
                      </td>
                      <td className="table-cell">
                        <p className="text-gray-900 whitespace-no-wrap">{invoice.issued}</p>
                        <p className="text-gray-600 whitespace-no-wrap">{invoice.due}</p>
                      </td>
                      <td className="table-cell">
                        <span className={`status ${invoice.status.toLowerCase()}`}>{invoice.status}</span>
                      </td>
                      <td className="table-cell">
                        <input
                          type="text"
                          className="phone-number-input"
                          value={phoneNumbers[invoice.id] || ""}
                          onChange={(e) => handlePhoneNumberChange(invoice.id, e.target.value)}
                          placeholder="Enter phone number"
                        />
                      </td>
                      <td className="table-cell table-cell-center">
                        <RequestPaymentonClick
                          fractalpayClientKey={fractalpayClientKey}
                          amount={invoice.amount}
                          phone_number={phoneNumbers[invoice.id] || ""}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTableRequest;
